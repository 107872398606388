* {
    margin: 0;
    padding: 0;
}

nav {
    display: flex;
    justify-content: space-between; /* Alinha logo e menu */
    align-items: center;
    padding: 20px 0;
    background-color: white;
    height: 10vh;
    position: fixed; /* Fixar a navbar no topo */
    width: 100%; /* Faz a navbar ocupar toda a largura da tela */
    top: 0; /* Posiciona no topo da página */
    z-index: 1000; /* Garante que fique acima dos outros elementos */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra para destacar */
}

#nav-list a {
    color: black;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    transition: 0.3s;
}

#nav-list a:hover {
    color: #108cc3;
    opacity: 0.7;
    scale: 1.2;
}

img.logo {
    width: 60px;
    margin-left: 10%;
}

.nav-list {
    list-style: none;
    display: flex;
    padding-right: 10%;
}

.nav-list li {
    letter-spacing: 3px;
    margin-left: 32px;
}

/* Ícone do menu (hambúrguer) - só visível em telas pequenas */
.mobile-menu {
    display: none;
    cursor: pointer;
}

.mobile-menu div {
    width: 32px;
    height: 2px;
    background-color: black;
    margin: 8px;
    transition: 0.3s;
}

/* Estilos para telas menores (celulares e tablets) */
@media (max-width: 768px) {
    .nav-list {
        position: absolute;
        top: 8vh; /* Abaixo do header */
        right: 0;
        height: 100vh;
        width: 50%;
        background-color: white;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1;
        padding: 0;
    }

    .nav-list li {
        margin: 20px 0;
    }

    .nav-list.open {
        transform: translateX(0); /* Exibe o menu */
    }

    .mobile-menu {
        display: block;
    }
}
