/* Footer.css */

footer {
    background-color: #222;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    width: 100%;
}

.footer-content {
    max-width: 1000px;
    margin: 0 auto;
}

.footer-content p {
    margin: 5px 0;
}

.footer-content a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
}

.footer-content a:hover {
    text-decoration: underline;
}

.social-links {
    margin-top: 10px;
}
