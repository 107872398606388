/* General Styles */
.team-section {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(135deg, #103d87, #051227);
    color: white;
}

.team-section h2 {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 40px;
    color: #fff;
}

.team-members {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.team-member {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    max-width: 900px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    gap: 20px;
    color: black;
}

.team-member.reverse {
    flex-direction: row-reverse;
}

.team-photo {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 5px solid #ddd;
    transition: 0.3s;
}

.team-photo:hover{
    scale: 1.2;
}
.team-info {
    flex: 1;
    text-align: left;
}

.team-info h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.team-info p {
    margin-bottom: 10px;
    font-size: 1.2em;
}

.team-icons {
    display: flex;
    gap: 15px;
    font-size: 1.5em;
    margin-bottom: 15px;
}

.team-icons img {
    width: 25px;
    /* Definir a largura dos ícones */
    height: 25px;
    /* Definir a altura dos ícones */
    transition: 0.3s;
}

.team-icons img:hover {
    scale: 1.2;
}

.team-button {
    padding: 10px 20px;
    background-color: transparent;
    border: 2px solid black;
    border-radius: 30px;
    font-size: 1.1em;
    cursor: pointer;
    transition: 0.3s;
}
.team-button:hover {
    scale: 1.2;
    background-color: black;
    color: #fff;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .team-member {
        flex-direction: column;
        text-align: center;
    }

    .team-member.reverse {
        flex-direction: column;
    }

    .team-info {
        text-align: center;
    }

    .team-photo {
        width: 120px;
        height: 120px;
    }

    .team-icons{
        justify-content: center;
    }
}