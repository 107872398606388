.services {
    text-align: center;
    padding: 50px;
    background-color: #f9f9f9;
}

.services h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.service-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.service-box {
    background-color: white;
    width: 30%;
    padding: 20px;
    margin: 10px;
    border: 2px solid #ddd;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.service-box:hover{
    scale: 1.1;
}

.service-box h3 {
    font-size: 24px;
    margin-bottom: 15px;
}

.service-box p {
    font-size: 16px;
    color: #555;
}

.icon {
    width: 60px;
    height: 60px;
    margin: 0 auto 20px auto;
    background-size: contain;
    background-repeat: no-repeat;
}

.react-icon {
    background-image: url('../imgs/services_icons/1.png');
}

.ecommerce-icon {
    background-image: url('../imgs/services_icons/2.png');
}

.wordpress-icon {
    background-image: url('../imgs/services_icons/3.png');
}

.seo-icon {
    background-image: url('../imgs/services_icons/4.png');
}

.app-icon {
    background-image: url('../imgs/services_icons/5.png');
}

.server-icon {
    background-image: url('../imgs/services_icons/6.png');
}

@media screen and (max-width: 768px) {
    .service-box {
        width: 100%;
        margin: 10px 0;
    }
}
