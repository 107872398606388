/* Hero.css */
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100vh;
    background: linear-gradient(135deg, #103d87, #051227);
    color: white;
    padding: 0 100px;
    font-family: 'Arial', sans-serif;
    text-align: center;
    opacity: 0;
    animation: fadeIn 1.5s forwards;
}

/* Contêiner do texto */
.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 50%;
    animation: fadeIn 2s forwards;
    opacity: 0;
}

.text-container h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.text-container p {
    font-size: 1.5rem;
    margin-bottom: 40px;
}

.contact-btn {
    background-color: transparent;
    color: white;
    padding: 15px 30px;
    border-radius: 25px;
    font-size: 1.2rem;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
    cursor: pointer;
    opacity: 0;
    animation: fadeIn 2.5s forwards;
}

.contact-btn:hover {
    background-color: white;
    color: #051227;
    transform: scale(1.1);
}

/* Contêiner da imagem */
.img-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.img-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: 0.3s;
}

.img-container img:hover {
    transform: scale(1.2);
}

/* Animação de fade-in */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsividade para telas menores */
@media (max-width: 1024px) {
    .hero {
        flex-direction: column;
        padding: 50px;
        text-align: center;
    }

    .text-container {
        max-width: 100%;
        margin-bottom: 40px;
    }

    .img-container {
        max-width: 100%;
    }

    .text-container h1 {
        font-size: 3rem;
    }

    .text-container p {
        font-size: 1.2rem;
    }

    .contact-btn {
        font-size: 1rem;
        padding: 12px 25px;
    }
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
    .hero {
        padding: 100px;
        height: auto;
        /* Ajuste de altura automática para telas menores */
        justify-content: center;
        align-items: center;
    }

    .text-container h1 {
        font-size: 3rem;
        justify-content: center;
        align-items: center;
    }

    .text-container p {
        font-size: 1.5rem;
        justify-content: center;
        align-items: center;
    }

    .contact-btn {
        font-size: 0.9rem;
        padding: 10px 20px;
    }

    .img-container {
        max-width: 100%;
        width: 100%;
    }

    .img-container img {
        width: 100%;
        /* Reduzir o tamanho da imagem em telas pequenas */
    }
}