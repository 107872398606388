* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
}

.container-principal {
    background-color: white;
    display: flex;
    height: 100vh;
    justify-content: space-around;
    align-items: center;
    padding: 0 10%;
}

.texto-principal {
    width: 50%;
    color: black;
    line-height: 1.6;
    margin-right: 20px;
    text-align: center;
    
}

.texto-principal p {
    font-size: 30px;
    font-weight: 400;
    align-items: center;
    
}

.imagem-principal {
    width: 50%; /* Manter a largura */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px; /* Mantém uma altura mínima para a imagem */
}

.imagem-principal img {
    width: 100%; /* Certifica-se que a imagem ocupe 100% da div */
    height: auto; /* Mantém a proporção da imagem */
    object-fit: cover; /* Caso a imagem tenha que se ajustar ao container */
    transition: 0.3s;
}

.imagem-principal img:hover{
    scale: 1.1;
}




/* Responsividade para telas menores */
@media (max-width: 1024px) {
    .container-principal {
        padding: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    .texto-principal {
        width: 90%;
        text-align: center;
        margin-right: 0;
    }

    .imagem-principal {
        width: 80%;
        margin-top: 20px;
    }

    .texto-principal p {
        font-size: 28px;
    }
}

@media (max-width: 768px) {
    .texto-principal p {
        font-size: 24px;
    }

    .imagem-principal {
        width: 100%;
    }
}
