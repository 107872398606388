.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    font-family: 'Arial', sans-serif;
    color: #000;
    flex-direction: row;
    /* Manter layout horizontal em telas grandes */
}

.contact-content {
    flex: 1;
    text-align: left;
    /* Alinha o texto e o link à esquerda */
}

.contact-content h1 {
    font-size: 64px;
    line-height: 1.2;
    margin-bottom: 20px;
}

.highlight {
    background-color: #0a53a4;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 64px;
}

.contact-link {
    display: inline-block;
    margin-top: 20px;
    background-color: white;
    border: 2px solid #0a53a4;
    color: #0a53a4;
    padding: 15px 30px;
    font-size: 20px;
    border-radius: 30px;
    text-decoration: none;
    cursor: pointer;
}

.contact-link:hover {
    background-color: #0a53a4;
    color: white;
}

/* Improved contact-info */
.contact-info {
    flex: 0.5;
    text-align: right;
    font-size: 18px;
    line-height: 1.5;
    color: #333;
}

/* Improved info-item */
.info-item {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 25px;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.info-item:hover {
    background-color: #e6f2ff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.info-item img {
    margin-right: 20px;
    width: 28px;
    height: 28px;
}

.info-item p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    color: #0a53a4;
}

.info-item a {
    color: black;
    text-decoration: none;
    font-size: 18px;
}

.info-item a:hover {
    color: #004080;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        padding: 20px;
        text-align: center;
        /* Alinhar o conteúdo ao centro em dispositivos móveis */
    }

    .contact-content h1 {
        font-size: 48px;
        /* Reduzir o tamanho da fonte em telas menores */
    }

    .highlight {
        font-size: 48px;
        /* Reduzir o tamanho da fonte do destaque em telas menores */
    }

    .contact-link {
        margin-top: 20px;
        font-size: 18px;
        /* Ajustar o tamanho da fonte do link */
    }

    .contact-info {
        margin-top: 30px;
        /* Dar espaço entre o texto e as informações de contato */
        text-align: center;
        /* Centralizar as informações de contato */
        font-size: 16px;
    }

    .info-item {
        justify-content: center;
        /* Centralizar os ícones e o texto */
    }

    .info-item img {
        margin-right: 10px;
        width: 20px;
        /* Reduzir o tamanho dos ícones em telas menores */
        height: 20px;
    }

    .info-item p {
        font-size: 16px;
        /* Reduzir o tamanho do texto em telas menores */
    }
}

/* Ajustes para telas muito pequenas, como smartphones */
@media (max-width: 480px) {
    .contact-content h1 {
        font-size: 36px;
        /* Reduzir ainda mais o tamanho da fonte em telas muito pequenas */
    }

    .highlight {
        font-size: 36px;
        /* Reduzir ainda mais o tamanho da fonte do destaque */
    }

    .contact-link {
        font-size: 16px;
        /* Ajustar o tamanho da fonte do link */
        padding: 12px 25px;
        /* Ajustar o padding do link */
    }

    .info-item img {
        width: 18px;
        /* Ajustar o tamanho do ícone */
        height: 18px;
    }

    .info-item p {
        font-size: 14px;
        /* Ajustar o tamanho do texto */
    }
}
