/* Global resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Portfolio Section */
.portfolio-section {
    padding: 80px 20px;
    text-align: center;
    background: linear-gradient(135deg, #101728, #26324d);
    /* Dark blue gradient background */
    color: white;
}

.portfolio-section h2 {
    font-size: 2.5em;
    margin-bottom: 50px;
    color: white;
}

/* Portfolio Card Styling */
.portfolio-card {
    background-color: #4a90e2;
    /* Blue background to simulate a placeholder for the iframe */
    width: 100%;
    max-width: 900px;
    height: 400px;
    /* Fixed height to simulate the look of an iframe container */
    margin: 20px auto;
    /* Center the card with margin */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
}

/* If you want an iframe, it will fill the card */
.portfolio-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
}

.portfolio-link {
    display: inline-block;
    margin-top: 10px;
    background-color: #1e3d73;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
}

.portfolio-link:hover {
    background-color: #051227;
}

/* Button Styling */
.show-more-btn {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 1.2em;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.show-more-btn:hover {
    background-color: #051227;
}

/* For better responsiveness */
@media (max-width: 768px) {
    .portfolio-section h2 {
        font-size: 2em;
    }

    .portfolio-card {
        height: 300px;
    }

    .portfolio-link {
        font-size: 1em;
        padding: 8px 16px;
    }

    .show-more-btn {
        font-size: 1em;
        padding: 8px 16px;
    }
}