* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
}

.about-us {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(135deg, #103d87, #051227);
    /* Background for the first section */
    color: white;
    /* Text color for the first section */
}

.about-us-b {
    text-align: center;
    padding: 60px 20px;
    background-color: white;
    color: black;
    /* Text color for the first section */
}

.about-us h2 {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 40px;
}

.why-content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    margin-bottom: 40px;
}

.why-content-b {
    display: flex;
    background-color: white;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    margin-bottom: 40px;
}

.why-item {
    display: flex;
    align-items: center;
    gap: 25px;
    max-width: 900px;
    padding: 30px;
    background-color: #0b2e6f;
    border-radius: 8px;
}

.why-item img {
    width: 80px;
    height: 80px;
}

.why-text {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.why-text h3 {
    font-size: 1.8em;
    margin-bottom: 10px;
    font-weight: bold;
}

.why-text p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #ddd;
}

.right-align {
    justify-content: flex-end;
    /* Aligns the content to the right */
    flex-direction: row-reverse;
    /* This places the image on the right and the text on the left */
}

.right-align .why-text {
    text-align: left;
    /* Ensures the text remains left-aligned */
}


/* Second section with white background and black text */
.white-section {
    background-color: white;
    color: black;
    padding: 60px 20px;
}

.white-section .why-item {
    background-color: white;
    /* Remove the background color of the items */
    color: black;
    box-shadow: none;
}

.white-section .why-text p {
    color: #333;
    /* Set paragraph text to dark gray */
}

@media (max-width: 768px) {
    .why-item {
        flex-direction: column;
        text-align: center;
        /* Center-align for a better look */
    }

    .why-item img {
        margin-bottom: 20px;
        /* Add some space between the icon and text */
    }

    .right-align .why-text {
        text-align: center;
        /* Ensure right-aligned text is centered */
    }
}